


let svg = `
<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="7.1px" height="7.1px" viewBox="0 0 7.1 7.1" enable-background="new 0 0 7.1 7.1" xml:space="preserve">
<defs>
</defs>
<g>
	<path fill="#F59331" stroke="#FFDC38" stroke-width="0.1" stroke-linecap="round" stroke-linejoin="round" d="M3.6,0.1
		c0,0-0.3,0-0.2,0c0,0-0.2,0-0.2,0c-0.2,0-0.8,0.2-0.9,0.2c0,0-0.1,0.1-0.1,0.1C2,0.4,1.9,0.4,1.8,0.5c0,0-0.1,0.1-0.1,0.1
		c0,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1C1.2,1,1.1,1,1.1,1.1c0,0,0,0,0,0
		c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.2C0.2,2.2,0.1,2.9,0.1,3.5
		c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.2,0
		c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.8-0.4,1-0.6c0.4-0.2,0.7-1,0.7-1.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.2
		c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1C3.2,0.7,3.1,0.7,3.1,0.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.1,0.2-0.2
		C3.4,0.1,3.6,0.1,3.6,0.1z"/>
	<path fill="#F59331" stroke="#FFDC38" stroke-width="0.1" stroke-linecap="round" stroke-linejoin="round" d="M7,3.7
		C7,3.7,7,3.7,7,3.7c0,0.1,0,0.2,0,0.2C7,3.9,7,4,7,4c0,0,0,0.1,0,0.1c0,0,0,0.2,0,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0-0.1,0.2-0.1,0.2
		c0,0,0,0.1-0.1,0.2c0,0-0.1,0.2-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1
		c0,0-0.1,0.2-0.1,0.2c0,0-0.2,0.2-0.2,0.2c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.2,0.1-0.2,0.1c0,0-0.2,0.1-0.2,0.1
		C4.8,6.9,4.2,7,3.5,7c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1C3.9,6.7,4,6.6,3.9,6.5c0-0.1,0-0.1-0.1-0.2
		c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0,0,0.1-0.1,0.1-0.2c0,0,0,0,0-0.1C3.9,5.1,4,5.1,4,5C4,5,4,4.9,4.1,4.9c0,0,0.1-0.1,0.1-0.1c0,0,0.2-0.3,0.2-0.2
		c0,0,0.2-0.2,0.3-0.3C4.7,4.2,4.8,4.1,4.9,4C5,4,5.1,3.9,5.2,3.9c0,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.2,0
		c0,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0.1C6.3,3.9,6.4,3.9,6.5,4c0.1,0,0.2,0,0.3-0.1C6.9,3.9,7,3.7,7,3.7c0,0,0-0.1,0-0.1
		C7,3.5,7,3.7,7,3.7"/>
	<path fill="#F59331" stroke="#FFDC38" stroke-width="0.1" stroke-linecap="round" stroke-linejoin="round" d="M0.1,3.5
		C0.1,3.5,0,3.6,0.1,3.5c0,0.1,0,0.3,0,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0.1,0.5,0.2,0.6
		c0.1,0.4,0.4,0.8,0.7,1.2c0.6,0.6,1.3,1,2.2,1.2c0.1,0,0.3,0,0.4,0C3.2,7,3,6.7,3.1,6.4c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0.1-0.1
		c0.1,0,0.1-0.1,0.1-0.1c0-0.2,0-0.5-0.1-0.7C3.1,5,2.8,4.6,2.5,4.3C2.2,4,1.8,3.8,1.3,3.8c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0
		c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1
		C0.2,3.7,0.1,3.6,0.1,3.5C0.1,3.6,0.1,3.5,0.1,3.5C0.1,3.5,0.1,3.5,0.1,3.5C0.1,3.5,0.1,3.5,0.1,3.5"/>
	<path fill="#F59331" stroke="#FFDC38" stroke-width="0.1" stroke-linecap="round" stroke-linejoin="round" d="M3.6,0.1
		c0.8,0,1.6,0.3,2.2,0.8c0.3,0.3,0.6,0.6,0.8,1c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.2,0.1,0.3C7,2.9,7,2.9,7,3c0,0,0,0.1,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0,0.2,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1
		C6.8,3.2,6.8,3.2,6.8,3.1c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.1-0.7-0.1C5.2,3.1,5,3,4.8,2.9C4.5,2.6,4.2,2.3,4,2
		C3.9,1.8,3.9,1.6,3.8,1.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2C3.9,0.8,3.9,0.7,4,0.7c0-0.1,0-0.2,0-0.3C3.9,0.2,3.8,0.1,3.6,0.1
		"/>
</g>
</svg>

`;

let testicon = L.divIcon({
  // html: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="arcs"> <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"> </path> </svg>`,
 html: `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 20 20"><circle cx="10" cy="10" r="10" stroke="currentColor" fill="#ff7700" /></path></svg>`,
  //html: svg.toString(),
  iconUrl: `/images`,
  className: "homeIcon",
  iconSize: [5, 5],
  iconAnchor: [10, 10],
});

export { testicon };