import L from "leaflet";
import "leaflet-layervisibility";
import { testicon } from "./icons"; 



  let main = document.querySelector("main");


  // landing page video looping because it stutter

  const landEl = document.querySelector("#landing");
  const landElVid = landEl.querySelector("figure");
  const mapid = main.querySelector("#mapid");

  const navsSerie = document.querySelectorAll(".serieNav");

  function leave(event){
    event.stopPropagation();
    if (event.deltaY > 30){
      landEl.classList.add("hideland");
      setTimeout(() => {
        landEl.classList.add("hidden");
      }, 1001);
      setTimeout(() => {
        mapid.style.visibility = "visible";
      }, 2);
    }
   }
   function trig (){
    landEl.removeEventListener("wheel",leave,true);
  }

  let observerThree = new IntersectionObserver ((entry)=> 
  entry.forEach(ent => {
    
    if(ent.isIntersecting){ 
       landEl.addEventListener("wheel",leave,true)}
     else {
      trig();
    }
    }
    ,{threshold: 0.9}));
  

    let containersLand = document.querySelector('#detector');
    observerThree.observe(containersLand);


  // landEl.addEventListener("wheel",(event)=>{
  //   console.log(event.deltaY);
  //   if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight ) {
  //     if (event.deltaY > 80){
  //       console.log("change");
  //     }
  //   }
  // })

document.querySelector('#landingvid').addEventListener('ended', function(e) {
  e.target.currentTime = 0;
  e.target.play();
}, false);


// function to open and close series menu 

const menuSerie = document.querySelector("#menuSerie");
const menuRecherche = document.querySelector("#menuRecherche");
const previewContainerBtn = document.querySelector('#containerCloseBtn');
const previewContainer = document.querySelector('#previewContainer');



function openCloseSerie(){

  const corpsArchive = document.querySelector("#corpsArchive");
  const closeMenuSerie = document.querySelector("#closeMenuSerie");

  corpsArchive.addEventListener("click", ()=>{opClose("open",menuSerie);opClose("close",menuRecherche)});
  closeMenuSerie.addEventListener("click",()=>opClose("close",menuSerie));

  
  let series = [...main.querySelectorAll(".serie")];
  
  function checkIfSerieIsOpened(){
    series.forEach(e => {
      if (e.classList.contains("show")){
        e.classList.remove("show");
      };
    });
  }

  var linksSerie = menuSerie.querySelectorAll("li");
  linksSerie.forEach(f => f.addEventListener("click",()=>{
  
    checkIfSerieIsOpened();
    const ser = document.querySelector(`#serie-${f.dataset.name}`);
    document.querySelector(`#serie-${f.dataset.name}`).classList.add('show');
    main.classList.add("fullBlock");
   
    setTimeout(()=>{
      map.invalidateSize();
      lanOrPor(ser);
    },550); 
    opClose("close", menuSerie);
  }))
  

}

function clearUrl(){
  window.history.replaceState({}, document.title, "/");
}


function openCloseRecherche(){

  const Recherche = document.querySelector("#Recherche");
  const closeMenuRecherche = document.querySelector("#closeMenuRecherche");

  Recherche.addEventListener("click", ()=> {opClose("open",menuRecherche); opClose("close",menuSerie)});
  closeMenuRecherche.addEventListener("click",()=>{
    opClose("close",menuRecherche);
    clearUrl();

});
  

}

// function to open or close an element. takes two argument : 
// @action : "open" or "close" depending on what you want to do
// @element : takes a DOM element as argument, it's the element you to be displaying or not

function opClose (action, element){
  switch (true){
    case (action === "open") :
      element.style.display ="block"
      element.style.visibility = "visible";
      break;
    case (action === "close") :
      element.style.visibility = "hidden";
      element.style.display ="none"
      break;
  }
}

openCloseRecherche();
openCloseSerie();


// function for the notes

var preview= document.querySelector("#notesPreview");
function notesBasDePageRecherche(){
  
  let visibleNotes =[];
  let observer = new IntersectionObserver ((ent)=>{
    ent.forEach(en => {
      if (en.isIntersecting){
        var _target = en.target.dataset.notes;
        var nodeToShow = preview.querySelector(`#nbp_${_target}`);
        nodeToShow.style.display = "block";
        preview.style.display ="block";
        visibleNotes.push(_target);
        preview.style.visibility="visible";
      
      }
      else {
        var i = visibleNotes.findIndex(e=>e == `${en.target.dataset.notes}`);
        visibleNotes.splice(i,1);
        
        for (const nbp of preview.children) {
          if(visibleNotes.includes(nbp.dataset.notes) !== true ){
            preview.querySelector(`#nbp_${nbp.dataset.notes}`).style.display= 'none';
          }
        }
        
      }
    }); if(visibleNotes.length == 0){
      preview.style.display ="none";
    }
  
  },{threshold: 0});

  
  [...menuRecherche.querySelectorAll('sup')].forEach(e=> observer.observe(e));

  let observerTwo = new IntersectionObserver ((entry)=> 
    entry.forEach(ent => {
      if(ent.isIntersecting)
      {preview.style.visibility ="hidden"}
      },{threshold: 0}));
  observerTwo.observe(document.querySelector('#notes'));





}
notesBasDePageRecherche();

let ariane = document.querySelector('.ariane ul');

//leaflet map

var mapCenter = [0, -20];
//var layer = L.tileLayer("http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png", {
  // var layer = L.tileLayer("https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png", {
//  var layer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}', {  
//   attribution: "\xA9 OpenStreetMap contributors, \xA9 CartoDB"
// });

var layer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
	attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
});


var map = L.map("mapid", {
  scrollWheelZoom: true,
  center: mapCenter,
  zoomSnap: 0.5,
  zoom: 3.5,
  minZoom:2.5,
  wheelPxPerZoomLevel: 150
});

map.addLayer(layer);

// instanciate markers

let markers = []

let allSeries = document.querySelectorAll(".serie");
let allMarkers = document.querySelectorAll(".markerSection");
allMarkers.forEach((marker,index) => {
  let imageMarker = function(){
    if( marker.querySelector('figure  img').src !== undefined || null ) {
      return marker.querySelector('figure  img').src
    }else {
      return marker.querySelector('source').src;
    }

  } 
  let iconMarker = L.icon({
    iconUrl: imageMarker
  })
  markers[index] = new L.marker(marker.dataset.location.split(','),
    {
      link: `${marker.id}`,
      id: `${marker.dataset.markerId}`,
      // icon: iconMarker
      icon: testicon
    })
    .on('click', function () {

      contentToggle(map, markers[index], marker);
      addToAriane(marker.id, ariane, 'li')
      })
    .addTo(map);

  //console.log('markerClasslist', marker);
  if (marker.dataset.classList) {
    L.DomUtil.addClass(markers[index]._icon, 
      `${marker.dataset.classList} 
       ${marker.dataset.markerId} 
       linkTo${marker.id}`);
  }
})

let lastMarkers;

// function for toggling the content when you click on a marker

function contentToggle(map, markerMap, marker) {

  for (const block of allSeries) {block.classList.remove('show')};
    main.classList.add('fullBlock');
    marker.closest('.serie').classList.add("show");
    setTimeout(() => {lanOrPor(marker.closest('.serie'))}, 550);

  if (marker.dataset.markerCollection) {
    main.querySelectorAll(".markerSection").forEach(el => {
      if(el.dataset.markerCollection == marker.dataset.markerCollection) {
        el.classList.add("show");
        
      }
    })
    let target = document.querySelector(`[data-marker-id=${markerMap.options.id}]`);
    
    target.scrollIntoView( {behavior: "smooth", block: "nearest", inline:'nearest' });
  
  }
  let latLngs = markerMap.getLatLng();
  lastMarkers = latLngs;
  
  setTimeout(function () {

    map.invalidateSize();
    map.flyTo(latLngs,8,{
        animate: true,
       duration: 1 
      })
  }, 550)


}


let allArticles = document.querySelectorAll('.items article');

document.querySelector('#hideBlock').addEventListener("click", function () {
  main.classList.remove("fullBlock");
  
  for (const block of allSeries) { block.classList.remove("show") }
  for (const block of allArticles) { block.classList.remove("show") }
  if (document.querySelector('.lock')) {
    document.querySelector(".lock").classList.remove("lock");
  }
  if (previewContainer.style.visibility == "visible") {
  
    opClose("close",previewContainer)
  }

    setTimeout(function () {
      map.invalidateSize();
      map.flyTo(lastMarkers, 7, {
        animate: true,
        duration: 1 
      });
    }, 550);
 
})

// "scroll to anchor" like function in the header  of each serie

let serieScroll = [...document.querySelectorAll(".serieAnchor")];
serieScroll.forEach(e=>{
  e.addEventListener("click",(ev)=>{
 
    
    let elementToScrollId = ev.target.parentNode.parentNode.dataset.serie;
    let element = document.querySelector(`#${elementToScrollId }`);


    let elementToScroll = element.querySelector(`${ev.target.dataset.targt}`);
    let offset = element.querySelector("nav").getBoundingClientRect().height;
    let elementToScrollPos = elementToScroll.getBoundingClientRect().top;
    let scrollable = document.querySelector(".items");
    let scrollAmount = scrollable.scrollTop;
    let offsetPos = elementToScrollPos + scrollAmount - (offset*1.3); 
    scrollable.scroll({top:offsetPos,behavior:"smooth"});
  })
  
})


//create the tag list

let tagList = new Set();
document.querySelectorAll('.menu input').forEach(tag => {
  tagList.add(tag.name);
})

//console.log('tagList',tagList);
document.querySelectorAll(".menu input").forEach(input => {
  input.addEventListener('change', function () {
    if (input.checked) {
       tagList.add(input.name)
    } else {
      tagList.delete(input.name)
    }
    console.log(tagList);
    document
      .querySelectorAll(`.leaflet-marker-icon.${input.name}`)
      .forEach(el => {
        let count = 0;
        tagList.forEach(tag =>{
          if (el.classList.contains(tag)) {
            count++;
          }
        })
        console.log(count)
        if (count == 0) {
          el.classList.add("hide");
            } else {
          el.classList.remove("hide");
        }
      })
  })
})



// work with colletcion :
// →  get all the event of a collection .


// →  create a layer group
// →  hightlight ’em when passing on the image:w



// https://gist.github.com/jeffreymorganio/359591dee3b08323d7f5609206ea4fcc
// function centerLeafletMapOnMarker(map, marker) {
//  var latLngs = [ marker.getLatLng() ];
//  var markerBounds = L.latLngBounds(latLngs);
//  map.fitBounds(markerBounds);
// }
//
// resize obverser for the map!
// const resizeObserver = new ResizeObserver(() => {
// });

// resizeObserver.observe(document.querySelector("#mapid"));

// Filter markers from the outside using classes!
// hide the marker if it has no more class from the nav list using set.
//
//


//interaction observer when a marker gets in
//
// Set things up
//
//
//

window.onload = () => {
    let observer = new IntersectionObserver(observables => {
      for (let observable of observables) {
      let obj = document.querySelector(`.${observable.target.dataset.markerId}`);
     // console.log(observable.target.dataset.location);
      if (observable.intersectionRatio > 0.5 && obj !== null) {
        obj.classList.add("active");
        observable.target.classList.add("indianred");
        lastMarkers =L.latLng(observable.target.dataset.location.split(","));
        map.flyTo(L.latLng(observable.target.dataset.location.split(",")));
      } else {
        obj?.classList.remove("active");
        observable.target.classList.remove("indianred");
      }
    }
  }, {
      threshold: [.5]
    }
  )

  const sections = document.querySelectorAll('.markerSection'); 

  for (let section of sections) {
    observer.observe(section)
  }


}

document.querySelector("#menuClose").addEventListener('click', function () {
  this.closest(".menu").classList.remove("show");
})
if (document.querySelector('#showTags')) {
  document.querySelector("#showTags").addEventListener('click', function () {
    document.querySelector(".menu").classList.add("show");
  })
}

document.querySelectorAll(".markerSection .otherLinks a").forEach(link => {
  link.addEventListener('click', function (e) {

    // add the marker id to the link and call the toggleContent(function)
    // document.querySelector(e.target.href)
    //
    document.querySelector(".items").classList.remove('lock')
      // document.querySelector('.items').classlist.remove("lock");
    // find target hash:
    let markerSectionLink = document.querySelector(`#${e.target.href.split('#')[1]}`);
    //console.log(markers);
    let markerNew;
    markers.forEach(mark => {
      console.log('mark', mark);
      if (mark.options.icon.id == e.target.dataset.markerID) {
        //console.log(mark);
        markerNew = mark
      }
    })
      

  console.log('markernew', markerNew);
    // contentToggle(map, e.target.dataset.markerId, markerSectionLink);
    contentToggle(map, markerNew, markerSectionLink);
    addToAriane(markerSectionLink.id, ariane, 'li')
     
  })
})



function addToAriane(link, ariane, tag) {
  //console.log(link);
  //add image
  
  let image = `<img src="${document.querySelector(`#${link} img`).src}" />`; 
  // console.log('img', image)
  let _newLink = document.createElement("li");
  let newLink = `<a href="#${link}">${image}</a>`;
  _newLink.insertAdjacentHTML('beforeend', newLink);
  ariane.appendChild(_newLink);
  _newLink.addEventListener("click", (e)=>{showFromArianne(e)});
}
function showFromArianne(event){
  let _target = event.currentTarget.children[0];
  if (event.currentTarget.children[0].tagName === 'A') {
    main.classList.add('fullBlock');
    const ser = document.querySelector(`#${_target.href.split('#')[1]}`).closest('.serie');
   setTimeout(() => {lanOrPor(ser)}, 550);
    
  
    document.querySelector('.items').classList.remove('lock');
    document.querySelectorAll('article').forEach(e => {e.classList.remove('show')});
    document.querySelectorAll('.serie').forEach(e => {e.classList.remove("show");})
    console.log(_target);
    document.querySelector(`#${_target.href.split('#')[1]}`).closest('.serie').classList.add("show");
  }

}


document.querySelectorAll('.showSomething').forEach(button => {

  button.addEventListener('click', function () {

      if (button.nextElementSibling.classList.contains('show')) {

        button.nextElementSibling.classList.remove('show');
      } else {

        button.nextElementSibling.classList.add('show');
      }
  })
})



document.querySelectorAll('figure .meta a').forEach(link => {

  link.addEventListener('click', function () {

    this.closest('.items').classList.add('lock');
    console.log(document.querySelector(`#${this.href.split("#")[1]}`).closest("article").classList.add('show'));
    // console.log(document.querySelector(`#${this.href.split("#")[1]}`).closest('figure').nextElementSibling());
    // document.querySelector(`#${this.href.split("#")[1]}`).closest('figure').nextElementSibling().classList.add('show');
    // document.querySelector(`#${this.href.split('#')[1]}`).closest('article').tagList.add("show");
  })
})

document.querySelectorAll('.closefiche').forEach(close => {
  close.addEventListener('click', function () {
    this.closest('.items').classList.remove('lock');
    this.closest('article').classList.remove('show')
  })
})





previewContainerBtn.addEventListener("click", (e)=>{
    opClose("close",e.currentTarget.parentNode);
    
  
  })
document.querySelectorAll('.fiche').forEach((fiche) => {
  
  fiche.addEventListener("click", (e)=>{
    let containerChildren = [...previewContainer.children];
    if (containerChildren.length > 1){previewContainer.removeChild(containerChildren[1])};
    opClose("open", previewContainer);
    let _id = e.currentTarget.getAttribute('id');  
    let path = e.currentTarget.dataset.path;
    
    let ficheContent = document.querySelector(`#${path}`);
    let ficheClone = document.importNode(ficheContent.content,true)
    previewContainer.appendChild(ficheClone);
    addToAriane(_id,ariane,'li');

    
  })
}
  
  )


map.on('zoomend', function() {
  document.querySelector("#mapid").style.setProperty('--zoom-level', this.getZoom())
});


let videos = document.querySelectorAll('video');
   
let isPaused = true; /* Flag for auto-paused video */
let observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            //console.log(entry);
            entry.target.play(); 
            isPaused = false;
        }
        else {entry.target.pause(); isPaused = true}
    });
}, {threshold: 0.5});
/*Avec un threshold de 1 il faut que toute la video soit visible, avec seulement 1px en overflow
et la video n'est pas suffisament visible. D'ou un threshold de 0.8 par exemple */
for (let video of videos) {
    observer.observe(video);
}


// landscape or portrait images 

function lanOrPor (serie){
let seriePhoto = serie.querySelector('#serie-photo');
let serieCoulisse = serie.querySelector('#serie-coulisse');
  let coulisses = serieCoulisse.querySelectorAll('img');
  let images = seriePhoto.querySelectorAll('img');
  [...images].forEach((image)=>{ verify(image)});
  [...coulisses].forEach((im)=>{ verify(im)});

  function verify(image){
    if (image.parentNode.classList.contains('landscape') == false || image.parentNode.classList.contains('landscape') == false ) {

      if (image.clientWidth / image.clientHeight >= 1 ) {
        image.parentNode.classList.add('landscape');
      }else{
        image.parentNode.classList.add('portrait');
      }
      
    }
  }
}

let items = document.querySelectorAll('.items')[0];
[...navsSerie].forEach(nav => {
  

  nav.parentNode.addEventListener("wheel", (e)=>{
    let h1 = nav.querySelector('h1');
    let navH = nav.clientHeight;
    let breakpoint = navH/items.scrollTop;
    let fontSize = 3;
    if(breakpoint > 2 && fontSize > 2){
      fontSize -= 3.5-(1/breakpoint);
      h1.style.fontSize = `${3-(1/breakpoint)}em`
      nav.style.padding = `${3-(1/breakpoint)}% 0px ${3-(1/breakpoint)}% 4%`
    } 
   

  })
})